/* src/index.css */
/* Import standardized variables first so they're available to all imports */
@import './styles/variables.css';

/* Import shared component styles */
@import './styles/buttons.css';
@import './styles/cards.css';
@import './styles/layout.css';
@import './styles/animations.css';
@import './styles/typography.css';
@import './styles/sections.css';

/* Import the fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;600&family=Lora:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');

/* Base styles */
body {
  font-family: var(--font-body);
  font-size: var(--text-lg);
  line-height: 1.6;
  color: var(--color-gray-700);
  background-color: var(--color-gray-100);
  margin: 0;
  padding: 0;
}

/* Page layout with sidebar nav */
.container, 
.content-container, 
main, 
[role="main"],
section[class*="Page"],
div[class*="Page"] {
  padding-top: 0.5rem;
  min-height: calc(100vh - 70px);
  transition: all 0.3s ease-in-out;
}

/* Style for content with top navbar margin (added dynamically in Navbar.js) */
.main-content {
  margin-top: 70px;
  transition: margin-top 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .main-content {
    margin-top: 70px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
  font-weight: 600;
  line-height: 1.2;
  margin-top: 0;
  color: var(--color-gray-800);
}

h1 {
  font-size: var(--text-5xl);
}

h2 {
  font-size: var(--text-4xl);
}

h3 {
  font-size: var(--text-3xl);
}

h4 {
  font-size: var(--text-2xl);
}

p {
  margin-bottom: var(--spacing-4);
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: var(--color-teal-primary);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--color-teal-dark);
}

/* Simple reset */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Loading indicator styling */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  transition: margin-left 0.3s ease-in-out;
}

/* Loading container will use the same styling as other main content containers */
.main-content.loading-container {
  margin-top: 70px;
}

@media (max-width: 768px) {
  .main-content.loading-container {
    margin-top: 70px;
  }
}

