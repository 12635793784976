/* src/styles/styles.css */

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

h1, h2, h3, h4 {
  font-family: 'Helvetica', sans-serif;
}

a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
