/* Emergency fix for navbar spacing */
body .main-content,
body [class*="Page"],
body .container,
body main,
body [role="main"],
body .dashboard-container,
body div[class*="Page"],
body section[class*="Page"],
body .userHomeContainer,
body .home-container {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

/* Section Hero special spacing */
body .section--hero {
  padding-top: 70px !important;
  margin-top: 0 !important;
}

@media (max-width: 768px) {
  body .section--hero {
    padding-top: 60px !important;
  }
}

/* Fix for specific components */
body .classSwitcherHeader {
  margin-top: 0 !important;
}

body {
  padding-top: 70px !important;
  margin: 0 !important;
}

/* UserHome specific fix */
body .userHomeContainer {
  margin-top: 8px !important;
}

@media (max-width: 768px) {
  body {
    padding-top: 60px !important;
  }
  
  body .userHomeContainer {
    margin-top: 5px !important;
  }
}
