/* src/styles/variables.css */
:root {
  /* Color Palette - Base Colors */
  --color-teal-primary: #38B2AC;
  --color-teal-light: #76c7c0;  
  --color-teal-dark: #2c9a95;
  --color-teal-ultra-light: #e6f7f7;
  
  --color-green-primary: #38a169;
  --color-green-light: #48bb7d;
  --color-green-dark: #2f855a;
  --color-green-ultra-light: #f0fdf4;
  
  --color-gray-100: #f9f9f9;
  --color-gray-200: #e2e8f0;
  --color-gray-300: #cbd5e0;
  --color-gray-400: #a0aec0;
  --color-gray-500: #718096;
  --color-gray-600: #4a5568;
  --color-gray-700: #2d3748;
  --color-gray-800: #1a202c;
  
  --color-white: #ffffff;
  --color-gold: #F6C177;
  
  /* Accent Colors */
  --color-pastel-peach: #FDEDE6;
  --color-pastel-pink: #F8D5D1;
  --color-pastel-blue: #D7EEF2;
  
  /* Status and Feedback Colors */
  --color-success: #2ecc71;
  --color-warning: #f1c40f;
  --color-error: #e74c3c;
  --color-info: #3498db;
  
  /* Dashboard-specific Colors */
  --color-achievement: #9b59b6;
  --color-achievement-bg: #f5eef8;
  --color-progress: #9ad3bc;
  --color-progress-hover: #80c2ac;
  --color-progress-bg: #eef6f3;
  --color-challenge: #e67e22;
  --color-challenge-bg: #f9fcec;
  
  /* Semantic Color Roles */
  --color-primary: var(--color-teal-primary);    /* Primary brand color */
  --color-primary-light: var(--color-teal-light); /* Lighter shade of primary */
  --color-primary-dark: var(--color-teal-dark);  /* Darker shade of primary */
  --color-primary-bg: var(--color-teal-ultra-light); /* Ultra light background */
  
  --color-secondary: var(--color-green-primary);    /* Secondary brand color */
  --color-secondary-light: var(--color-green-light); /* Lighter shade of secondary */
  --color-secondary-dark: var(--color-green-dark);  /* Darker shade of secondary */
  --color-secondary-bg: var(--color-green-ultra-light); /* Ultra light background */
  
  --color-text-primary: var(--color-gray-800);    /* Main text color */
  --color-text-secondary: var(--color-gray-600);  /* Secondary text color */
  --color-text-tertiary: var(--color-gray-500);   /* Less important text */
  --color-text-accent: var(--color-teal-primary); /* Accent text */
  
  --color-bg-primary: var(--color-white);         /* Primary background color */
  --color-bg-secondary: var(--color-gray-100);    /* Secondary background color */
  --color-bg-tertiary: var(--color-pastel-blue);  /* Tertiary background color */
  
  --color-border: var(--color-gray-200);          /* Border color */
  --color-border-hover: var(--color-gray-300);    /* Border hover color */
  
  /* Typography */
  --font-heading: 'Lexend', sans-serif;
  --font-body: 'Lora', serif;
  --font-accent: 'Caveat', cursive;
  
  /* Font Sizes */
  --text-xs: 0.75rem;    /* 12px */
  --text-sm: 0.875rem;   /* 14px */
  --text-base: 1rem;     /* 16px */
  --text-lg: 1.125rem;   /* 18px */
  --text-xl: 1.25rem;    /* 20px */
  --text-2xl: 1.5rem;    /* 24px */
  --text-3xl: 1.875rem;  /* 30px */
  --text-4xl: 2.25rem;   /* 36px */
  --text-5xl: 3rem;      /* 48px */
  
  /* Font Weights */
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  
  /* Line Heights */
  --line-height-tight: 1.2;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.75;
  
  /* Spacing */
  --spacing-1: 0.25rem;  /* 4px */
  --spacing-2: 0.5rem;   /* 8px */
  --spacing-3: 0.75rem;  /* 12px */
  --spacing-4: 1rem;     /* 16px */
  --spacing-6: 1.5rem;   /* 24px */
  --spacing-8: 2rem;     /* 32px */
  --spacing-12: 3rem;    /* 48px */
  --spacing-16: 4rem;    /* 64px */
  
  /* Component-specific Spacing */
  --spacing-section: 2rem;
  --spacing-card-gap: 1rem;
  --spacing-dashboard-section: 2.5rem;
  
  /* Border Radius */
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 12px;
  --radius-xl: 16px;
  --radius-full: 9999px;
  
  /* Border Widths */
  --border-width-thin: 1px;
  --border-width-normal: 2px;
  --border-width-thick: 3px;
  
  /* Shadows */
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
  --shadow-focus: 0 0 0 3px rgba(56, 178, 172, 0.3);
  
  /* Transitions */
  --transition-fast: 150ms ease;
  --transition-normal: 200ms ease;
  --transition-slow: 300ms ease;
  
  /* Z-indices */
  --z-index-dropdown: 10;
  --z-index-sticky: 20;
  --z-index-fixed: 30;
  --z-index-modal-backdrop: 40;
  --z-index-modal: 50;
  --z-index-popover: 60;
  --z-index-tooltip: 70;
}