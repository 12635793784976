/* src/styles/cards.css */

/* ===== Basic Card Styles ===== */
.card {
  background-color: var(--color-bg-primary);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
  overflow: hidden;
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  border: 1px solid rgba(0, 0, 0, 0.04);
  position: relative;
}

/* Add subtle hover effect to all cards */
.card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

/* Card with explicit hover class */
.card--hover:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.12);
}

/* Card sections */
.card__header {
  padding: var(--spacing-8) var(--spacing-8) var(--spacing-4);
}

.card__body {
  padding: 0 var(--spacing-8) var(--spacing-8);
}

.card__footer {
  padding: var(--spacing-6) var(--spacing-8);
  border-top: 1px solid var(--color-border);
  background-color: rgba(0, 0, 0, 0.02);
}

/* ===== Card Variations ===== */

/* Compact card */
.card--compact {
  padding: var(--spacing-6);
}

/* Highlight card */
.card--highlight {
  border: 2px solid var(--color-primary);
  box-shadow: 0 10px 20px rgba(56, 178, 172, 0.1);
}

.card--highlight:hover {
  box-shadow: 0 15px 30px rgba(56, 178, 172, 0.15);
}

/* Popular card */
.card--popular {
  position: relative;
}

.card--popular::before {
  content: "Popular";
  position: absolute;
  top: 0;
  right: var(--spacing-8);
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: var(--spacing-2) var(--spacing-4);
  border-radius: 0 0 var(--radius-md) var(--radius-md);
  font-size: var(--text-sm);
  font-weight: var(--font-weight-semibold);
  letter-spacing: 0.5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Featured card */
.card--featured {
  border-bottom: 4px solid var(--color-primary);
}

/* Cards with decorative elements */
.card--decorative {
  overflow: visible;
}

.card--decorative::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: radial-gradient(circle, var(--color-pastel-blue), transparent 70%);
  top: -30px;
  right: -30px;
  z-index: -1;
  opacity: 0.7;
}

/* ===== Dashboard Card Variations ===== */

/* Achievement Card */
.card--achievement {
  background-color: var(--color-bg-primary);
  border: var(--border-width-thin) solid var(--color-border);
  padding: var(--spacing-6);
  min-width: 160px;
  max-width: 200px;
  flex-shrink: 0;
  text-align: center;
  transition: all var(--transition-normal);
  border-radius: var(--radius-lg);
}

.card--achievement:hover {
  border-color: var(--color-achievement);
  background-color: var(--color-achievement-bg);
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.card--achievement.unlocked {
  border-color: var(--color-achievement);
  background-color: var(--color-achievement-bg);
}

/* Stat Card */
.card--stat {
  display: flex;
  align-items: center;
  gap: var(--spacing-6);
  padding: var(--spacing-6);
  border-radius: var(--radius-lg);
}

.stat--skills {
  border-top: var(--border-width-thick) solid var(--color-success);
}

.stat--challenges {
  border-top: var(--border-width-thick) solid var(--color-warning);
}

.stat--minutes {
  border-top: var(--border-width-thick) solid var(--color-primary);
}

/* Focus Skill Card */
.card--focus-skill {
  width: 300px;
  text-align: center;
  padding: var(--spacing-6);
  border: var(--border-width-thin) solid var(--color-border);
  cursor: pointer;
  transition: all var(--transition-normal);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--spacing-6);
  border-radius: var(--radius-lg);
}

.card--focus-skill:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
  border-color: var(--color-primary-light);
}

.card--focus-skill.completed {
  border-color: var(--color-success);
  box-shadow: 0 10px 20px rgba(46, 204, 113, 0.15);
}

/* Challenge Card */
.card--challenge {
  padding: var(--spacing-6);
  background-color: var(--color-challenge-bg);
  border-left: var(--border-width-thick) solid var(--color-challenge);
  border-radius: var(--radius-lg);
}

/* ===== Card Content Elements ===== */

.card__title {
  font-family: var(--font-heading);
  font-size: var(--text-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  margin-bottom: var(--spacing-3);
  line-height: 1.2;
}

.card__subtitle {
  font-family: var(--font-heading);
  font-size: var(--text-lg);
  font-weight: var(--font-weight-medium);
  color: var(--color-text-secondary);
  margin-bottom: var(--spacing-6);
  line-height: 1.4;
}

.card__image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: var(--radius-md);
  margin-bottom: var(--spacing-4);
}

.card__text {
  font-family: var(--font-body);
  font-size: var(--text-lg);
  color: var(--color-text-secondary);
  margin-bottom: var(--spacing-6);
  line-height: var(--line-height-relaxed);
}

.card__icon {
  font-size: var(--text-4xl);
  margin-bottom: var(--spacing-4);
  color: var(--color-primary);
}

.card__actions {
  display: flex;
  justify-content: center;
  gap: var(--spacing-3);
  margin-top: auto;
}

/* Card tag/label */
.card__tag {
  display: inline-block;
  padding: var(--spacing-1) var(--spacing-3);
  background-color: var(--color-primary-bg);
  color: var(--color-primary-dark);
  border-radius: var(--radius-full);
  font-size: var(--text-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--spacing-4);
}

/* ===== Card Layout Utilities ===== */

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: var(--spacing-8);
}

.card-scroll {
  display: flex;
  overflow-x: auto;
  gap: var(--spacing-6);
  padding: var(--spacing-4) 0;
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar in Firefox */
}

.card-scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, Opera */
}

/* ===== Card Animations ===== */

.card-animate-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.card-animate-in.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Staggered card animation */
.card-grid.staggered > *:nth-child(1) { transition-delay: 0.1s; }
.card-grid.staggered > *:nth-child(2) { transition-delay: 0.2s; }
.card-grid.staggered > *:nth-child(3) { transition-delay: 0.3s; }
.card-grid.staggered > *:nth-child(4) { transition-delay: 0.4s; }
.card-grid.staggered > *:nth-child(5) { transition-delay: 0.5s; }
.card-grid.staggered > *:nth-child(6) { transition-delay: 0.6s; }

/* ===== Responsive Adjustments ===== */

@media (max-width: 767px) {
  .card {
    margin-bottom: var(--spacing-6);
  }
  
  .card-grid {
    grid-template-columns: 1fr;
    gap: var(--spacing-6);
  }
  
  .card__header {
    padding: var(--spacing-6) var(--spacing-6) var(--spacing-4);
  }
  
  .card__body {
    padding: 0 var(--spacing-6) var(--spacing-6);
  }
  
  .card__footer {
    padding: var(--spacing-4) var(--spacing-6);
  }
  
  .card--focus-skill {
    width: 100%;
    max-width: 100%;
  }
  
  .card__text {
    font-size: var(--text-base);
  }
}