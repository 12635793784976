/* src/styles/sections.css */

/* ===== Common Section Styling ===== */
.section {
  padding: var(--spacing-16) var(--spacing-4);
  position: relative;
  overflow: hidden;
}

/* ===== Section Variants ===== */

/* Hero section */
.section--hero {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--color-bg-secondary);
  padding: var(--spacing-16) var(--spacing-4);
  position: relative;
  z-index: 1;
}

/* CTA section */
.section--cta {
  padding: var(--spacing-12) var(--spacing-8);
  text-align: center;
  background-color: var(--color-bg-primary);
  box-shadow: var(--shadow-lg);
  border-radius: var(--radius-lg);
  max-width: 900px;
  margin: var(--spacing-16) auto;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.section--cta::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: radial-gradient(circle, var(--color-pastel-blue), transparent 70%);
  top: -100px;
  right: -100px;
  z-index: -1;
  opacity: 0.6;
}

/* Features section */
.section--features {
  padding: var(--spacing-16) var(--spacing-4);
  text-align: center;
  background-color: var(--color-bg-secondary);
  position: relative;
}

/* Testimonials section */
.section--testimonials {
  padding: var(--spacing-16) var(--spacing-4);
  background-color: var(--color-bg-tertiary);
  text-align: center;
  position: relative;
}

.section--testimonials::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3C/svg%3E") no-repeat;
  background-size: cover;
}

/* Benefits section */
.section--benefits {
  padding: var(--spacing-16) var(--spacing-4);
  background-color: var(--color-bg-primary);
  position: relative;
}

/* FAQ section (new) */
.section--faq {
  padding: var(--spacing-16) var(--spacing-4);
  background-color: var(--color-bg-secondary);
  position: relative;
}

/* Contact section */
.section--contact {
  padding: var(--spacing-16) var(--spacing-4);
  background-color: var(--color-bg-secondary);
  text-align: center;
  position: relative;
}

/* ===== Section Content Containers ===== */
.section__content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.section__content--narrow {
  max-width: 800px;
}

.section__content--wide {
  max-width: 1400px;
}

/* Section with decorative elements */
.section--decorative {
  position: relative;
  overflow: hidden;
}

.section--decorative::before {
  content: "";
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: radial-gradient(circle, var(--color-pastel-blue), transparent 70%);
  top: -200px;
  right: -200px;
  z-index: 1;
  opacity: 0.3;
}

.section--decorative::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle, var(--color-pastel-peach), transparent 70%);
  bottom: -150px;
  left: -150px;
  z-index: 1;
  opacity: 0.3;
}

/* ===== Section Headers ===== */
.section__header {
  margin-bottom: var(--spacing-12);
  text-align: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.section__title {
  font-family: var(--font-heading);
  font-size: var(--text-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  margin-bottom: var(--spacing-6);
  line-height: 1.1;
  letter-spacing: -0.01em;
}

.section__subtitle {
  font-family: var(--font-heading);
  font-size: var(--text-xl);
  font-weight: var(--font-weight-medium);
  color: var(--color-text-secondary);
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  margin-bottom: var(--spacing-4);
}

.section__divider {
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, var(--color-primary-light), var(--color-primary));
  margin: var(--spacing-6) auto var(--spacing-8);
  border-radius: var(--radius-full);
}

.section__tag {
  display: inline-block;
  padding: var(--spacing-1) var(--spacing-4);
  background-color: var(--color-primary-bg);
  color: var(--color-primary-dark);
  border-radius: var(--radius-full);
  font-size: var(--text-sm);
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--spacing-4);
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

/* ===== Grid Layouts ===== */
.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: var(--spacing-8);
  margin-top: var(--spacing-12);
}

.two-column-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-8);
  margin-top: var(--spacing-8);
  align-items: center;
}

.three-column-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-8);
  margin-top: var(--spacing-8);
}

/* ===== Section Animations ===== */
.section-animate-in {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.section-animate-in.visible {
  opacity: 1;
  transform: translateY(0);
}

/* ===== Responsive Adjustments ===== */
@media (max-width: 991px) {
  .two-column-grid,
  .three-column-grid {
    grid-template-columns: 1fr;
    gap: var(--spacing-6);
  }
}

@media (max-width: 767px) {
  .section {
    padding: var(--spacing-12) var(--spacing-4);
  }
  
  .section--hero {
    min-height: 70vh;
    padding: var(--spacing-12) var(--spacing-4);
  }
  
  .section--cta {
    padding: var(--spacing-8) var(--spacing-4);
    margin: var(--spacing-12) var(--spacing-2);
  }
  
  .section__header {
    margin-bottom: var(--spacing-8);
  }
  
  .section__title {
    font-size: var(--text-3xl);
  }
  
  .section__subtitle {
    font-size: var(--text-lg);
  }
  
  .feature-grid {
    grid-template-columns: 1fr;
    gap: var(--spacing-6);
    margin-top: var(--spacing-8);
  }
  
  .section__divider {
    margin: var(--spacing-4) auto var(--spacing-6);
  }
}