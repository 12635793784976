/* src/styles/typography.css */

/* ===== Heading Styles ===== */
.heading {
  font-family: var(--font-heading);
  font-weight: var(--font-weight-bold);
  line-height: 1.1;
  margin-bottom: var(--spacing-6);
  color: var(--color-text-primary);
  letter-spacing: -0.02em;
}

.heading--xl {
  font-size: var(--text-5xl);
}

.heading--lg {
  font-size: var(--text-4xl);
}

.heading--md {
  font-size: var(--text-3xl);
}

.heading--sm {
  font-size: var(--text-2xl);
}

.heading--xs {
  font-size: var(--text-xl);
}

/* Heading variants */
.heading--accent {
  color: var(--color-primary);
}

.heading--secondary {
  color: var(--color-secondary);
}

.heading--light {
  color: var(--color-white);
}

.heading-1 {
  font-size: var(--text-5xl);
  font-weight: var(--font-weight-bold);
  line-height: 1.1;
  margin-bottom: var(--spacing-6);
  font-family: var(--font-heading);
  color: var(--color-text-primary);
  letter-spacing: -0.02em;
}

.heading-2 {
  font-size: var(--text-4xl);
  font-weight: var(--font-weight-bold);
  line-height: 1.1;
  margin-bottom: var(--spacing-6);
  font-family: var(--font-heading);
  color: var(--color-text-primary);
  letter-spacing: -0.01em;
}

.heading-3 {
  font-size: var(--text-3xl);
  font-weight: var(--font-weight-semibold);
  line-height: 1.2;
  margin-bottom: var(--spacing-4);
  font-family: var(--font-heading);
  color: var(--color-text-primary);
}

.heading-4 {
  font-size: var(--text-2xl);
  font-weight: var(--font-weight-semibold);
  line-height: 1.2;
  margin-bottom: var(--spacing-4);
  font-family: var(--font-heading);
  color: var(--color-text-primary);
}

.heading-5 {
  font-size: var(--text-xl);
  font-weight: var(--font-weight-semibold);
  line-height: 1.3;
  margin-bottom: var(--spacing-2);
  font-family: var(--font-heading);
  color: var(--color-text-primary);
}

/* ===== Text Styles ===== */
.text {
  font-family: var(--font-body);
  margin-bottom: var(--spacing-4);
  line-height: var(--line-height-relaxed);
  color: var(--color-text-secondary);
}

.text--xl {
  font-size: var(--text-xl);
}

.text--lg {
  font-size: var(--text-lg);
}

.text--md {
  font-size: var(--text-base);
}

.text--sm {
  font-size: var(--text-sm);
}

.text--xs {
  font-size: var(--text-xs);
}

/* Text variants */
.text--muted {
  color: var(--color-text-tertiary);
}

.text--accent {
  color: var(--color-text-accent);
}

.text--light {
  color: var(--color-white);
}

.text-body {
  font-family: var(--font-body);
  font-size: var(--text-lg);
  line-height: var(--line-height-relaxed);
  color: var(--color-text-secondary);
  margin-bottom: var(--spacing-6);
}

.text-bold {
  font-weight: var(--font-weight-bold);
}

.text-semibold {
  font-weight: var(--font-weight-semibold);
}

/* ===== Special Text Styles ===== */
.tagline {
  font-family: var(--font-heading);
  font-size: var(--text-3xl);
  font-weight: var(--font-weight-medium);
  color: var(--color-primary-dark);
  line-height: 1.3;
  margin-bottom: var(--spacing-6);
}

.subtitle {
  font-family: var(--font-heading);
  font-size: var(--text-xl);
  font-weight: var(--font-weight-medium);
  color: var(--color-text-secondary);
  margin-bottom: var(--spacing-6);
  max-width: 700px;
  line-height: 1.4;
}

.accent-text {
  font-family: var(--font-accent);
  color: var(--color-primary);
}

/* ===== Font Weights ===== */
.font-regular {
  font-weight: var(--font-weight-normal);
}

.font-medium {
  font-weight: var(--font-weight-medium);
}

.font-semibold {
  font-weight: var(--font-weight-semibold);
}

.font-bold {
  font-weight: var(--font-weight-bold);
}

/* ===== Line Heights ===== */
.leading-tight {
  line-height: var(--line-height-tight);
}

.leading-normal {
  line-height: var(--line-height-normal);
}

.leading-relaxed {
  line-height: var(--line-height-relaxed);
}

/* ===== Responsive Adjustments ===== */
@media (max-width: 767px) {
  .heading--xl, .heading-1 {
    font-size: var(--text-4xl);
  }
  
  .heading--lg, .heading-2 {
    font-size: var(--text-3xl);
  }
  
  .heading--md, .heading-3 {
    font-size: var(--text-2xl);
  }
  
  .heading--sm, .heading-4 {
    font-size: var(--text-xl);
  }
  
  .heading--xs, .heading-5 {
    font-size: var(--text-lg);
  }
  
  .tagline {
    font-size: var(--text-2xl);
  }
  
  .subtitle {
    font-size: var(--text-lg);
  }
  
  .text-body {
    font-size: var(--text-base);
  }
}