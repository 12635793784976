/* src/styles/buttons.css */

/* ===== Base Button Styles ===== */
.btn {
  display: inline-block;
  font-family: var(--font-heading);
  font-weight: var(--font-weight-semibold);
  padding: 0.75rem 1.75rem;
  border: none;
  border-radius: var(--radius-full);
  cursor: pointer;
  transition: all var(--transition-normal);
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  line-height: 1.2;
  position: relative;
  overflow: hidden;
  box-shadow: var(--shadow-sm);
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: var(--shadow-md);
}

.btn:active {
  transform: translateY(-1px);
}

.btn:focus-visible {
  outline: none;
  box-shadow: var(--shadow-focus);
}

.btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
  pointer-events: none;
}

/* Shimmer effect on hover */
.btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.btn:hover:after {
  transform: translateX(100%);
}

/* ===== Button Variants ===== */

/* Primary button - now uses semantic color variables */
.btn--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.btn--primary:hover {
  background-color: var(--color-primary-dark);
}

/* Secondary button (teal) */
.btn--secondary {
  background-color: var(--color-secondary);
  color: var(--color-white);
}

.btn--secondary:hover {
  background-color: var(--color-secondary-dark);
}

/* Subtle button (light gray) */
.btn--subtle {
  background-color: var(--color-gray-200);
  color: var(--color-text-primary);
}

.btn--subtle:hover {
  background-color: var(--color-gray-300);
}

/* Outline buttons */
.btn--outline-primary {
  background-color: transparent;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
  box-shadow: none;
}

.btn--outline-primary:hover {
  background-color: var(--color-primary-bg);
  border-color: var(--color-primary-dark);
  color: var(--color-primary-dark);
  box-shadow: var(--shadow-sm);
}

.btn--outline-secondary {
  background-color: transparent;
  border: 2px solid var(--color-secondary);
  color: var(--color-secondary);
  box-shadow: none;
}

.btn--outline-secondary:hover {
  background-color: var(--color-secondary-bg);
  border-color: var(--color-secondary-dark);
  color: var(--color-secondary-dark);
  box-shadow: var(--shadow-sm);
}

/* Status buttons */
.btn--success {
  background-color: var(--color-success);
  color: var(--color-white);
}

.btn--success:hover {
  background-color: #27ae60; /* Darker success */
}

.btn--warning {
  background-color: var(--color-warning);
  color: #333;
}

.btn--warning:hover {
  background-color: #e5b910; /* Darker warning */
}

.btn--error {
  background-color: var(--color-error);
  color: var(--color-white);
}

.btn--error:hover {
  background-color: #c0392b; /* Darker error */
}

/* ===== Dashboard Specific Buttons ===== */
.btn--achievement {
  background-color: var(--color-achievement);
  color: var(--color-white);
}

.btn--achievement:hover {
  background-color: #8e44ad; /* Darker purple */
}

.btn--challenge {
  background-color: var(--color-progress);
  color: var(--color-white);
}

.btn--challenge:hover {
  background-color: var(--color-progress-hover);
}

/* ===== Icon Button ===== */
.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: var(--text-lg);
  border-radius: var(--radius-full);
  background-color: transparent;
  color: var(--color-text-tertiary);
  border: none;
  cursor: pointer;
  transition: all var(--transition-fast);
  box-shadow: none;
}

.btn-icon:hover {
  background-color: var(--color-gray-200);
  color: var(--color-text-primary);
  transform: translateY(0);
}

.btn-icon:focus-visible {
  outline: none;
  box-shadow: var(--shadow-focus);
}

/* ===== Button with Icon ===== */
.btn-with-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-2);
}

.btn-with-icon svg {
  width: 1.2em;
  height: 1.2em;
}

/* ===== Button Sizes ===== */
.btn--xs {
  font-size: var(--text-xs);
  padding: 0.3rem 0.6rem;
}

.btn--sm {
  font-size: var(--text-sm);
  padding: 0.5rem 1.2rem;
}

.btn--md {
  font-size: var(--text-base);
  padding: 0.75rem 1.75rem;
}

.btn--lg {
  font-size: var(--text-xl);
  padding: 1rem 2.25rem;
  font-weight: var(--font-weight-bold);
}

/* ===== Button Width Variations ===== */
.btn--full {
  display: block;
  width: 100%;
}

.btn--full-mobile {
  display: inline-block;
  width: auto;
}

/* ===== Button Groups ===== */
.btn-group {
  display: flex;
  gap: var(--spacing-4);
  flex-wrap: wrap;
}

.btn-group--center {
  justify-content: center;
}

.btn-group--end {
  justify-content: flex-end;
}

.btn-group--space-between {
  justify-content: space-between;
}

/* ===== Responsive Adjustments ===== */
@media (max-width: 767px) {
  .btn {
    padding: 0.7rem 1.5rem;
  }
  
  .btn--lg {
    font-size: var(--text-lg);
    padding: 0.85rem 1.75rem;
  }
  
  .btn-group {
    gap: var(--spacing-3);
  }
  
  .btn--full-mobile {
    display: block;
    width: 100%;
    margin-bottom: var(--spacing-3);
  }
  
  .btn-group--stacked-mobile {
    flex-direction: column;
  }
  
  .btn-group--stacked-mobile .btn {
    width: 100%;
    margin-bottom: var(--spacing-3);
  }
}