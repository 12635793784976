/* src/components/LoadingSpinner/LoadingSpinner.css */

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
}

.loading-spinner-container.full-page {
  min-height: 70vh;
}

.spinner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-4);
}

.loading-spinner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Size variations */
.spinner-small {
  width: 30px;
  height: 30px;
}

.spinner-medium {
  width: 50px;
  height: 50px;
}

.spinner-large {
  width: 80px;
  height: 80px;
}

/* The outer circle */
.spinner-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--color-primary);
  animation: spin 1.5s linear infinite;
}

/* The inner circle */
.spinner-circle-inner {
  position: absolute;
  width: 70%;
  height: 70%;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--color-secondary);
  animation: spin 1s linear infinite reverse;
}

.loading-message {
  font-family: var(--font-heading);
  font-size: var(--text-base);
  color: var(--color-primary);
  margin: 0;
  text-align: center;
  opacity: 0.85;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Optional shimmer animation for the text */
.loading-message {
  position: relative;
  overflow: hidden;
}

.loading-message::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  100% {
    left: 150%;
  }
}