/* src/components/ErrorBoundary/ErrorBoundary.css */

.error-boundary {
  text-align: center;
  padding: 50px;
  background-color: #ffe6e6;
  border: 1px solid #ffcccc;
  border-radius: 8px;
  margin: 20px;
}

.error-boundary h2 {
  font-size: 2rem;
  color: #cc0000;
  margin-bottom: 20px;
}

.error-boundary p {
  font-size: 1.2rem;
  color: #660000;
  margin-bottom: 30px;
}

.retry-button {
  padding: 10px 20px;
  background-color: #76c7c0;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.retry-button:hover {
  background-color: #5aa9a4;
}
