/* src/styles/animations.css */

/* ===== Basic Animations ===== */

/* Fade in animation (up) */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.6s ease-out forwards;
  opacity: 0;
}

/* Fade in animation (left) */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-fade-in-left {
  animation: fadeInLeft 0.6s ease-out forwards;
  opacity: 0;
}

/* Fade in animation (right) */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-fade-in-right {
  animation: fadeInRight 0.6s ease-out forwards;
  opacity: 0;
}

/* Fade in with scale */
@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-fade-in-scale {
  animation: fadeInScale 0.4s ease-out forwards;
  opacity: 0;
}

/* ===== Dashboard-specific animations ===== */

/* Achievement unlock animation */
@keyframes achievementUnlock {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(10px);
  }
  50% {
    opacity: 1;
    transform: scale(1.1) translateY(0);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.animate-achievement-unlock {
  animation: achievementUnlock 0.8s ease-out forwards;
}

/* Progress update animation */
@keyframes progressUpdate {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-progress-update {
  background: linear-gradient(270deg, var(--color-progress-hover), var(--color-progress));
  background-size: 200% 200%;
  animation: progressUpdate 2s ease infinite;
}

/* Spin animation (for loading indicators) */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* ===== Hover Effects ===== */

/* Lift effect */
.hover-lift {
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
}

.hover-lift:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

/* Scale effect */
.hover-scale {
  transition: transform var(--transition-normal);
}

.hover-scale:hover {
  transform: scale(1.05);
}

/* Subtle hover for buttons and cards */
.hover-subtle {
  transition: transform var(--transition-fast), box-shadow var(--transition-fast), 
              background-color var(--transition-fast), color var(--transition-fast);
}

.hover-subtle:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

/* Focus effect */
.focus-visible:focus-visible {
  outline: none;
  box-shadow: var(--shadow-focus);
}

/* ===== Reusable Animations ===== */

/* Pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.animate-pulse {
  animation: pulse 2s infinite ease-in-out;
}

/* Bounce animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.animate-bounce {
  animation: bounce 2s infinite;
}

/* ===== Animation Delays ===== */
.delay-1 { animation-delay: 0.1s; }
.delay-2 { animation-delay: 0.2s; }
.delay-3 { animation-delay: 0.3s; }
.delay-4 { animation-delay: 0.4s; }
.delay-5 { animation-delay: 0.5s; }
.delay-6 { animation-delay: 0.6s; }
.delay-7 { animation-delay: 0.7s; }
.delay-8 { animation-delay: 0.8s; }

/* ===== Staggered Animations ===== */
.stagger-children > * {
  opacity: 0;
}

.stagger-children > *:nth-child(1) {
  animation: fadeIn 0.4s ease-out 0.1s forwards;
}

.stagger-children > *:nth-child(2) {
  animation: fadeIn 0.4s ease-out 0.2s forwards;
}

.stagger-children > *:nth-child(3) {
  animation: fadeIn 0.4s ease-out 0.3s forwards;
}

.stagger-children > *:nth-child(4) {
  animation: fadeIn 0.4s ease-out 0.4s forwards;
}

.stagger-children > *:nth-child(5) {
  animation: fadeIn 0.4s ease-out 0.5s forwards;
}

/* ===== Accessibility ===== */
@media (prefers-reduced-motion: reduce) {
  .animate-fade-in,
  .animate-fade-in-left,
  .animate-fade-in-right,
  .animate-fade-in-scale,
  .animate-achievement-unlock,
  .animate-progress-update,
  .animate-pulse,
  .animate-bounce,
  .animate-spin,
  .stagger-children > * {
    animation: none !important;
    opacity: 1 !important;
    transform: none !important;
    transition: none !important;
  }
  
  .hover-lift:hover,
  .hover-scale:hover,
  .hover-subtle:hover {
    transform: none;
    box-shadow: var(--shadow-sm);
  }
}