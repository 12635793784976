/* src/styles/layout.css */

/* Fix for navbar spacing */
.main-page-content {
  margin-top: 65px !important;
  padding-top: 0 !important;
}

/* Sections */
.section {
  padding: var(--spacing-16) var(--spacing-4);
}

.section--light {
  background-color: var(--color-gray-100);
}

.section--dark {
  background-color: var(--color-gray-800);
  color: var(--color-white);
}

.section--teal {
  background-color: var(--color-teal-light);
  color: var(--color-gray-800);
}

.section--accent {
  background-color: var(--color-pastel-blue);
}

/* Container */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-4);
  box-sizing: border-box;
}

.container--sm {
  max-width: 800px;
}

.container--lg {
  max-width: 1400px;
}

/* Flex layouts */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: var(--spacing-2);
}

.gap-4 {
  gap: var(--spacing-4);
}

.gap-6 {
  gap: var(--spacing-6);
}

.gap-8 {
  gap: var(--spacing-8);
}

/* Grid layouts */
.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: 1fr;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

/* Spacing utilities */
.m-0 { margin: 0; }
.m-1 { margin: var(--spacing-1); }
.m-2 { margin: var(--spacing-2); }
.m-4 { margin: var(--spacing-4); }

.mt-0 { margin-top: 0; }
.mt-1 { margin-top: var(--spacing-1); }
.mt-2 { margin-top: var(--spacing-2); }
.mt-4 { margin-top: var(--spacing-4); }
.mt-8 { margin-top: var(--spacing-8); }

.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: var(--spacing-1); }
.mb-2 { margin-bottom: var(--spacing-2); }
.mb-4 { margin-bottom: var(--spacing-4); }
.mb-8 { margin-bottom: var(--spacing-8); }
.mb-12 { margin-bottom: var(--spacing-12); }
.mb-16 { margin-bottom: var(--spacing-16); }

.p-0 { padding: 0; }
.p-1 { padding: var(--spacing-1); }
.p-2 { padding: var(--spacing-2); }
.p-4 { padding: var(--spacing-4); }
.p-6 { padding: var(--spacing-6); }
.p-8 { padding: var(--spacing-8); }

/* Text alignment */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* Display utilities */
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none;
}

/* Width utilities */
.w-full {
  width: 100%;
}

.w-1/2 {
  width: 50%;
}

.w-1/3 {
  width: 33.333333%;
}

.w-2/3 {
  width: 66.666667%;
}

/* Responsive adjustments */
@media (max-width: 1023px) {
  .lg\:hidden {
    display: none;
  }
  
  .grid-cols-3,
  .grid-cols-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .section {
    padding: var(--spacing-8) var(--spacing-4);
  }
  
  .md\:hidden {
    display: none;
  }
  
  .md\:block {
    display: block;
  }
  
  .grid-cols-2,
  .grid-cols-3,
  .grid-cols-4 {
    grid-template-columns: 1fr;
  }
  
  .w-1/2,
  .w-1/3,
  .w-2/3 {
    width: 100%;
  }
}