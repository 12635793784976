/* src/components/SkillsList/SkillsList.css */

.skills-list-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.skills-list-container h2 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 1em;
}

.skills-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 2em;
}

.skill-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 1em;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.skill-item:hover {
  background-color: #f9f9f9;
}

.skill-info {
  display: flex;
  flex-direction: column;
}

.skill-name {
  font-size: 1.2em;
  color: #2980b9;
  margin-bottom: 0.3em;
}

.skill-status {
  font-size: 0.95em;
  color: #7f8c8d;
}

.completed .skill-status {
  color: #27ae60;
}

.incomplete .skill-status {
  color: #e74c3c;
}

.view-details-button {
  background-color: #2980b9;
  color: white;
  padding: 0.6em 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.95em;
}

.view-details-button:hover {
  background-color: #21618c;
}

/* Back Button */
.back-button {
  background-color: transparent;
  color: #2980b9;
  padding: 0.6em 1em;
  border: 2px solid #2980b9;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.95em;
  display: block;
  margin: 0 auto;
}

.back-button:hover {
  background-color: #2980b9;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 600px) {
  .skill-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .view-details-button {
    margin-top: 1em;
    width: 100%;
  }
}
