.post-subscription-setup-container {
    max-width: 800px;
    margin: 80px auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    font-family: 'Roboto', sans-serif;
    color: #333;
    text-align: left;
  }
  
  .post-subscription-setup-container h1 {
    text-align: center;
    color: #3cab8c;
    margin-bottom: 1rem;
  }
  
  .post-subscription-setup-container p {
    margin-bottom: 1rem;
  }
  
  .congrats-message {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
  }
  
  .intro-message {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .setup-section {
    margin-bottom: 2rem;
  }
  
  .setup-section h2 {
    color: #3cab8c;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .info-text {
    font-size: 0.95rem;
    color: #555;
    margin-bottom: 1rem;
  }
  
  .text-input,
  .select-input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .avatar-gallery {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 0.5rem;
  }
  
  .avatar-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  
  .avatar-input {
    display: none;
  }
  
  .avatar-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: border-color 0.3s;
    margin-top: 0.25rem;
  }
  
  .avatar-image.selected {
    border-color: #3cab8c;
  }
  
  .add-class-row {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .classes-list .class-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .delete-class-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 0.3rem 0.7rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s;
  }
  
  .delete-class-button:hover {
    background-color: #c82333;
  }
  
  .plan-choice-row {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .finish-setup-row {
    text-align: center;
    margin-top: 2rem;
  }
  
  .primary-finish-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .primary-finish-button:hover {
    background-color: #0069d9;
    transform: translateY(-2px);
  }
  
  .session-note {
    text-align: center;
    color: #999;
    font-size: 0.85rem;
    margin-top: 1rem;
  }
  